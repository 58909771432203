/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button, Row } from 'react-bootstrap';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { Link } from 'gatsby';
import { Box } from '../../modules/common/components/box';

const REVIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page reverse'} game="reverse">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Reverse: 1999 wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Reverse: 1999. Check our guides, tier lists
          and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="New content" />
      <div className="promoted-content ">
        <div className="banner char-mercuria">
          <h4>Should you pull Mercuria?</h4>
          <Link to="/re1999/guides/should-you-pull-mercuria">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner char-pioneer">
          <h4>Should you build Pioneer?</h4>
          <Link to="/re1999/guides/should-you-build-pioneer">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
        <div className="banner char-kakania">
          <h4>Should you pull Kakania?</h4>
          <Link to="/re1999/guides/should-you-pull-kakania">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
      </div>
      {/* 
            <SectionHeader title="Active codes" />
      <p>There are currently no active codes.</p><div className="codes">
        <Box isCentered>
          <p className="code">DressRehearsal</p>
          <p className="rewards">Various rewards</p>
          <p className="date">Released on 15.07.2024</p>
        </Box>
        <Box isCentered>
          <p className="code">CurtainRises</p>
          <p className="rewards">Various rewards</p>
          <p className="date">Released on 15.07.2024</p>
        </Box>
        <Box isCentered>
          <p className="code">MournfulLament</p>
          <p className="rewards">Various rewards</p>
          <p className="date">Released on 15.07.2024</p>
        </Box>
        <Box isCentered>
          <p className="code">AThousandHamlets</p>
          <p className="rewards">Various rewards</p>
          <p className="date">Released on 15.07.2024</p>
        </Box>
      </div> */}
      {/* <span className="new">NEW!</span>{' '} */}
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/re1999/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_intro.png"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/re1999/guides/reroll"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_reroll.webp"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner guide"
          link="/re1999/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_beginner.png"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/re1999/characters"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_characters.png"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Tier list"
          link="/re1999/tier-list"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_tier.png"
              alt="Tier list"
            />
          }
        />
        <CategoryCard
          title="Skins"
          link="/re1999/skins"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_skins.jpg"
              alt="Skins"
            />
          }
        />
        <CategoryCard
          title="Team Building"
          link="/re1999/guides/team-building"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_teams.jpg"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Banner history (CN)"
          link="/re1999/guides/banner-history-cn"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_banners_cn.png"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Psychubes"
          link="/re1999/psychubes"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_psychube.png"
              alt="Psychubes"
            />
          }
        />
        <CategoryCard
          title="Psychubes Cheat Sheet"
          link="/re1999/guides/psychube-cheat-sheet"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_psycheat.png"
              alt="Psychube Cheat sheet"
            />
          }
        />
        <CategoryCard
          title="Insight Mats. Cheat Sheet"
          link="/re1999/guides/insight-cheat-sheet"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_insight.png"
              alt="Insight Cheat Sheet"
            />
          }
        />
      </Row>
      <SectionHeader title="Mane's Bulletin (Raid Boss) Guides" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Mane's Bulletin (Raid Boss)"
          link="/re1999/guides/mane-bulletin"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_raid.jpg"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Darkness of the Abyss"
          link="/re1999/guides/darkness-of-the-abyss"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_abyss.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Marsh Creation"
          link="/re1999/guides/marsh-creation"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_marsh.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Gold in the Cave"
          link="/re1999/guides/gold-in-the-cave"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_gold.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Hypothesis of Exhibition"
          link="/re1999/guides/hypothesis-of-exhibition"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_hypothesis.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Projection of Nightmares"
          link="/re1999/guides/projection-of-nightmares"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_projection.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Isle Cryptid"
          link="/re1999/guides/isle-cryptid"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_cryptid.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Echoes of Opera"
          link="/re1999/guides/echoes-of-opera"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_opera.webp"
              alt="Team Building"
            />
          }
        />
        <CategoryCard
          title="Bellows of the Earth"
          link="/re1999/guides/bellows-of-the-earth"
          image={
            <StaticImage
              src="../../images/reverse/categories/category_bellows.webp"
              alt="Team Building"
            />
          }
          isNew
        />
      </Row>
      <br />
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default REVIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Reverse: 1999 Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a wiki for Reverse: 1999. Check our guides, tier lists and reviews for characters available in the game."
    game="reverse"
  />
);
